import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const EditorialPolicyPage = ({ pageContext }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>PJK MARKETING LTD Editorial Policy</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              <span>Last Updated: September 20, 2023</span>
            </p>
            <p>
              <h2>1. Introduction</h2>
            </p>
            <p>
              <span>
                PJK MARKETING LTD ("we," "us," or "our") is committed to
                providing our visitors with accurate, reliable, and valuable
                information through our website,
              </span>
              <a
                href="https://ai-productreviews.com/"
                target="_blank"
                rel="noopener"
              >
                <span> </span>https://ai-productreviews.com/
              </a>
              <span>
                . This Editorial Policy outlines our principles and guidelines
                for creating, editing, and maintaining the content published on
                our platform.
              </span>
            </p>
            <p>
              <h2>2. Objectivity and Accuracy</h2>
            </p>
            <p>
              <span>
                We are dedicated to ensuring the accuracy and objectivity of our
                content. Our content is thoroughly researched, fact-checked, and
                reviewed by our editorial team before publication. We strive to
                provide information that is free from bias and misinformation.
              </span>
            </p>
            <p>
              <h2>3. Transparency</h2>
            </p>
            <p>
              <span>
                We believe in transparency and will clearly disclose any
                potential conflicts of interest, sponsorships, or affiliations
                that may influence the content we produce. Our readers deserve
                to know when content is sponsored or influenced by external
                parties.
              </span>
            </p>
            <p>
              <h2>4. Privacy and Data Protection</h2>
            </p>
            <p>
              <span>
                We respect user privacy and adhere to all applicable data
                protection laws. We do not collect or share personal information
                without user consent, and we maintain a Privacy Policy that
                outlines our data handling practices.
              </span>
            </p>
            <p>
              <h2>5. Corrections and Updates</h2>
            </p>
            <p>
              <span>
                In the event of errors or inaccuracies in our content, we are
                committed to promptly correcting and updating the information.
                Users are encouraged to report any inaccuracies they encounter.
              </span>
            </p>
            <p>
              <h2>6. Plagiarism and Copyright</h2>
            </p>
            <p>
              <span>
                We do not condone plagiarism and respect copyright laws. All
                content produced by our team is original, and we ensure proper
                attribution when referencing external sources.
              </span>
            </p>
            <p>
              <h2>7. Editorial Independence</h2>
            </p>
            <p>
              <span>
                Our editorial team operates independently from any commercial or
                financial interests. Editorial decisions are based on the merit
                and relevance of content to our audience, rather than external
                pressures.
              </span>
            </p>
            <p>
              <h2>8. User Feedback</h2>
            </p>
            <p>
              <span>
                We welcome feedback from our readers and consider it invaluable
                for improving our content. User feedback helps us better
                understand our audience's needs and preferences.
              </span>
            </p>
            <p>
              <span>Contact Information</span>
            </p>
            <p>
              <span>
                If you have any questions or concerns about our Editorial Policy
                or the content on our website, please contact us at:
              </span>
            </p>
            <p></p>
            <p>PJK MARKETING LTD</p>
            <p>Hope Cottage Draycott, Claverley,</p>
            <p>Wolverhampton, Shropshire,</p>
            <p>England, WV5 7EA</p>
            <p>Email: info@ai-productreviews.com</p>
            <p></p>
            <p>
              <span>Date of Policy: September 20, 2023</span>
            </p>
            <p>
              <span>
                This Editorial Policy reflects our commitment to providing
                high-quality and ethical content to our readers and will be
                periodically reviewed and updated as necessary to maintain these
                standards.
              </span>
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default EditorialPolicyPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Editorial Policy | AI Product Reviews"}
      description={
        "Explore our unbiased editorial policy, ensuring accurate AI product reviews. Trust our insights"
      }
      pathname={location.pathname}
    />
  );
};
